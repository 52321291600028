@import "../../../../../styles/main.scss";

.sites__page {
  .map {
    // top: 100px !important;
    // height: 300px;
    // width: 100%;
  }

  .object-slide {
    display: flex;
    margin: 1em 0;
    flex-wrap: wrap;
    .card {
      width: 200px;
    }
  }
}

.searchBarandResults_wrapperr{
  position: absolute;
  z-index: 999;
  top: 0px;
  padding: 0px;
  margin: 0px;
  height: 40px;
  width: 300px;
  left: 40px;
  .text-input__atom{
    margin: 0px;
  }
  // overflow: hidden;
}

input:focus {
  background-color: $primary-white;
}
.search-results-dropdown {
  position: absolute;
  background-color: #FFF;
  z-index: 999;
  top: 38px;
  width: 100%;
  @include box-shadow;
  max-height: 300px;
  overflow: auto;
  border-radius: 12px;
}

.top_spacer{
  height: 30px;
  width: 30px;
}

.find-me-btn {
  border-radius: 50%;
  padding: 10px;
  height: 35px;
  width: 35px;
  bottom: 20px;
  right: 40px;
  position: absolute;
  background: $primary-dark-blue;
  border: 1px solid #E9EAEA;
  bottom: 50px;
  right: 40px;
  z-index: 1000;
  content:url("../../../../../assets/images/marker.png");
  // filter: invert(1);
}

.object-slide-container{
  position: absolute;
    border-radius: 13px;
    background: #FFF;
    border: 1px solid #E9EAEA;
    top: 50px;
    width: 300px;
    right: 40px;
    left: auto;
    height: 55px;
    z-index: 1000;
    flex-wrap: wrap;
    overflow: hidden;
  &:hover{
    @include box-shadow;
  }
}

.object-slide {
  position: relative;
  height: 55px;
  width: 110%;
  z-index: 1000;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  .card {
    width: auto;
  }
}
.buttons{
  position: absolute;
  top: 20px;
  left: 600px;
  z-index: 100000;
}

.site-list-container{
  position: absolute;
  border-radius: 13px;
  background: #FFF;
  border: 1px solid #E9EAEA;
  top: 50px;
  width: 300px;
  right: 40px;
  left: auto;
  height: 55px;
  z-index: 1000;
  flex-wrap: wrap;
  overflow: hidden;
    &:hover{
      @include box-shadow;
    }
}

.site-list {
    width: 110%;
    height: 100%;
    overflow: auto;
    padding-right: 0px;
}

.list-nav-container {
  position:absolute;
  top: 105px;
  right: 40px;
  left: auto;
  width: 300px;
  height: 35px;
  z-index: 1000;
  overflow: hidden;
  .list-nav-button {
    position: relative;
    vertical-align: middle;
    width: 100px;
    height: 35px;
    background-color: transparent;
    border: none;
    border-radius: 8px;
    padding: 0;
    opacity: 100%;
    &:hover{
      opacity: 50%;
    }
    .list-nav-button-img {
      top: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      z-index: 1010;
      padding: 0px;
      margin: 0px;
      filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(239deg) brightness(95%) contrast(100%);
     
    }
  }
}

.feednot-nav-container-show {
  visibility: collapse;
  height: 0px;
  width: 0px;
  padding: 0px;
  margin: 0px;
}

.feednot-nav-containerr {
  position:absolute;
  top: 80px;
  width: 14px;
  height: 25px;
  border-radius: 6px 0px 0px 6px;
  z-index: 1000;
  background-color: $primary-dark-blue;
  overflow: hidden;
    .feednot-nav-button-img {
      position: relative;
      width: auto;
      height: 19px;
      z-index: 1010;
      padding: 3px;
      margin: 0px;
    }
}

.feednot-nav-containerl {
  position:absolute;
  top: 80px;
  width: 14px;
  height: 25px;
  border-radius: 0 6px 6px 0;
  z-index: 1000;
  background-color: $primary-dark-blue;
  overflow: hidden;
    .feednot-nav-button-img {
      position: relative;
      width: auto;
      height: 19px;
      z-index: 1010;
      padding: 3px;
      margin: 0px;
    }
}



@media (max-width: $mobile) {
  .sites__page {
    .object-slide {
      flex-direction: column;
      .card {
        width: inherit;
      }
    }
  }
}

@import "../../../styles/main.scss";

.post-card__molecule-profile {
  padding: 10px !important;
  margin: auto !important;
  margin-bottom: 20px !important;
  height: fit-content !important;
  width: 60% !important;
  background-color: white !important;
  position: relative !important;
  left: 10px !important;
  display: flex !important;
  flex-direction: column !important;
}

.post-card__molecule {
  padding: 10px;
  margin-bottom: 20px;
  height: fit-content;
  width: 90%;
  background-color: white;
  position: relative;
  left: 10px;
  display: flex;
  flex-direction: column;

  .post-profile-primary-container{
    display: flex;
    flex-direction: row;

    .post-profile-picture {
      height: 40px;
      width: 40px;
      border-radius: 20px;
    }
    
    .post-profile-body-container{
      padding: 2px;
      
      // .post-profile-picture:hover{
      //   cursor: pointer;
      //   border: none !important;
      //   box-shadow: none m !important;
      //   // border: 3px solid #116cd2;
      // }
      .post-profile-body {
        display: flex;
        margin-left: 10px;
        height: -moz-fit-content;
        height: fit-content;
        width: 350px;
        border-radius: 10px;
      }

      .post-profile-date {
        margin-left: 10px;
        font-size: 12px;
      }

      .icon {
        color: $primary-red;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }
  }

  .post-profile-count-container{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    border-bottom: 1px solid #C4C4C460;

    .post-profile-comment{
      margin-left: 10px;
      font-size: 14px;
    }

    .post-profile-comment-count{
      margin-left: 10px;
      font-size: 14px;
    }

    .post-profile-like{
      margin-left: 10px;
      font-size: 14px;
    }

    .post-profile-like-count{
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .post-profile-btn-container{
    padding: 0px;

    .post-profile-comment-btn{
      width: 50%;
      background-color: $primary-white;
      border: none;
      padding: 3px;
    }:hover {
        // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
    }

    .post-profile-like-btn{
      width: 50%;
      background-color: $primary-white;
      border: none;
      padding: 3px;
    }:hover {
        // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
      }
  }

  .postcard-comment {
    visibility: visible;
  }
  .postcard-comment-noshow{
    visibility: collapse;
    height: 0px;
    margin: 0px;
  }

  .post-comment-container{
    border-bottom: 1px solid #C4C4C460;

    .post-post{
      position: relative;
      display: flex;
      width: 90%;
      margin: auto;
      margin-bottom: 45px;
      top: 5px;
      z-index: 1000;
      height: -moz-fit-content;
      height: fit-content;
      border-radius: 5px;
      border: 1px solid #C4C4C460;
      .post-post-textarea{
        width: 100%;
        height: 30px;
        border-radius: 5px;
        resize: none;
      }
      .post-post-btn{
        position: absolute;
        margin-top: 3px;
        height: 30px;
        width: fit-content;
        top: 40px;
        right: 20px;
        background-color: $primary-dark-blue;
        color: $primary-white;
      }
    }
  
    .post-post-noshow{
      visibility: collapse;
      height: 0px;
      margin: 0px;
    }
  }
}

.inactive-post {
  background-color: $secondary-grey;
  cursor: not-allowed;
}

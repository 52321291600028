@import "../../../styles/main.scss";

.nav-menu__molecule {
  .nav-menu__molecule-itemr {
    display: flex;
    margin-left: 25px;
    color: #7D7F80;
    opacity: 1;
    //height: 40px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    font-size: 0.85em;
    font-weight: 600;
    .icon {
      min-width: 1.5em;
      margin-right: 15px;
    }
    &:hover {
      color: $primary-dark-blue;
      // opacity: 100%;
    }
  }
  .nav-menu__molecule-item-is-active {
    color: $primary-dark-blue;
    display: flex;
    //margin: 30px 0;
    margin-left: 25px;
    // color: #7D7F80;
    //height: 40px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    font-size: 0.85em;
    font-weight: 600;
    border-right: 5px solid $primary-dark-blue;
    .icon {
      min-width: 1.5em;
      margin-right: 15px;
    }
  }
  .nav-menu__molecule-item-signout{
    position:absolute;
    bottom: 20px;
    margin: 30px 0;
    margin-left: 50px;
    font-size: $font-m;

    cursor: pointer;
    font-size: $font-m;
    font-weight: $font-weight-light;
    .icon {
      min-width: 1.5em;
      margin-right: 15px;
    }
    &:hover {
      color: $primary-dark-blue;
    }
  }
  .side-bar__organism-app-version {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
}

@media (max-width: $tablet) {
  .nav-menu__molecule {
    margin-top: 0;
  }
}
